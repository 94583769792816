<template>
    <div class="group relative">
        <div class="relative mb-0 lg:mb-2">
            <img
                class="aspect-[209/163] w-full rounded-md object-cover"
                :src="data.image || data.image_mobile || '/assets/default.png'"
                :alt="data.name + '-thumbnail image'"
                onerror='this.onerror = null; this.src="/assets/default.png";'
                :class="{
                    'loader-image': isloaderImage,
                }"
                @load="removeclass"
            />
            <!-- Jackpot -->
            <div
                v-if="jackpotvalue"
                class="absolute bottom-0 inline-block rounded-tr-[4px] px-2 py-0.5 text-black lg:hidden"
                :style="`background: rgba(254, 221, 43, 0.85);`"
            >
                <div class="text-[11px] font-medium">
                    <span class="lg:mr-2">{{ $t('common.money_symbol') }}</span>
                    {{ Math.floor(jackpotvalue).toLocaleString('de') }}
                </div>
            </div>
            <button
                class="absolute top-0 h-full w-full lg:hidden"
                aria-label="Play"
                @click="handlePlayClick()"
            ></button>

            <!-- Partner Icon -->
            <span v-if="data.partner" class="absolute right-1 top-1">
                <img
                    :src="`${staticUrl}/assets/icons/casino/provider/${data?.partner?.toLowerCase()}-active.svg`"
                    :alt="`${data.partner}-icon`"
                    onerror="this.style.display='none'"
            /></span>
            <!-- hover effect -->
            <div
                class="invisible absolute top-0 z-20 hidden h-full w-full cursor-pointer flex-col items-center justify-center space-y-2 rounded-md bg-indigo-950 bg-opacity-75 transition-all duration-100 group-hover:visible lg:flex"
                @click="handlePlayClick()"
            >
                <button
                    class="rounded-l-full rounded-r-full bg-gradient-to-b from-[#ffd255] to-[#ffbf0d] px-5 lg:h-10"
                >
                    <div class="flex justify-center space-x-2">
                        <img
                            :src="`${staticUrl}/assets/img/icons/play.svg`"
                            alt="play icon"
                        />
                        <span
                            class="text-center text-sm font-[11px] capitalize text-black"
                        >
                            {{ $t('common.play') }}
                        </span>
                    </div>
                </button>
            </div>
        </div>
        <div class="truncate">
            <span
                class="hidden cursor-pointer text-sm capitalize md:text-sm lg:flex"
                @click="handlePlayClick()"
                >{{ data.name }}</span
            >
        </div>
        <!-- Jackpot -->
        <div
            v-if="jackpotvalue"
            class="hidden rounded-[4px] bg-[#f7d936] px-2 py-0.5 text-black lg:inline-block"
        >
            <div class="text-[11px] font-medium">
                <span class="lg:mr-2">{{ $t('common.money_symbol') }}</span>
                {{ Math.floor(jackpotvalue).toLocaleString('de') }}
            </div>
        </div>
    </div>
</template>
<script setup>
import { useModalStore, useUserStore } from '~/stores'

import { storeToRefs } from 'pinia'
const useModalStoreInstance = useModalStore()
const useUserStoreInstance = useUserStore()
const { showGameDenyModal } = storeToRefs(useModalStoreInstance)
const { isLoggedIn } = storeToRefs(useUserStoreInstance)
// eslint-disable-next-line vue/require-prop-types
const { data, jackpotvalue } = defineProps(['data', 'jackpotvalue'])
const staticUrl = useRuntimeConfig().public.staticUrl
const emit = defineEmits(['click'])
const handlePlayClick = () => {
    if (data.deny_info && isLoggedIn.value) {
        showGameDenyModal.value = true
    } else {
        emit('click')
    }
}

const isloaderImage = ref(true)
const removeclass = () => {
    isloaderImage.value = false
}
</script>
